import side from '../../svg/side-logo-right.svg';
import logo from '../../svg/enero-logo.svg';
import React from 'react';
import { TextField, Button } from '@material-ui/core';
import { isMobileOnly } from 'react-device-detect';
import { AttachIcon, PhotoIcon } from '../../Icons/Icons';
import clsx from 'clsx';
import { useForm } from 'react-hook-form';
import axios from 'axios';

type Inputs = {
  name: string;
  email: string;
  message: string;
};

// eslint-disable-next-line no-useless-escape
const validEmailRegex = /^[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;

export const Contact = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>();
  const [sending, setSending] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [postError, setPostError] = React.useState(false);

  const errorKeys = new Set(Object.keys(errors));

  async function submitForm(data: Inputs) {
    setSending(true);
    setSuccess(false);
    setPostError(false);

    try {
      const response = await axios.post('/contact_me.php', data);
      if (response.status === 200) {
        setSuccess(true);
      } else {
        setPostError(true);
      }
    } catch (e) {
      setPostError(true);
    } finally {
      setSending(false);
    }
  }

  return (
    <section className="flex flex-row justify-between items-center">
      <form
        onSubmit={handleSubmit(submitForm)}
        className="py-10 flex flex-col p-2 sm:p-6 gap-4">
        <section>
          <img src={logo} alt="enero" className="w-8/12 mb-10" />
          <h1 className="text-5xl">SEJA ENERO ENERGIA</h1>
        </section>
        <section className="flex flex-col gap-3">
          <h2 className="text-xl uppercase text-brand font-bold">
            Entre em contato com a gente
          </h2>
          <TextField
            label="Seu nome"
            variant="outlined"
            error={errorKeys.has('name')}
            inputProps={register('name', { required: true })}
          />
          <TextField
            label="Seu e-mail"
            variant="outlined"
            error={errorKeys.has('email')}
            inputProps={register('email', {
              required: true,
              pattern: validEmailRegex,
            })}
          />
          <TextField
            label="Escreva aqui sua mensagem"
            multiline={true}
            rows={5}
            error={errorKeys.has('message')}
            variant="outlined"
            inputProps={register('message', { required: true })}
          />
        </section>
        <section className="hidden flex-col gap-3">
          <h2 className="text-xl uppercase text-brand font-bold">
            Adiante uma simulação
          </h2>
          <p className="text-gray-600 text-sm">
            Nos envie uma conta de energia sua
          </p>
          <div className="grid grid-cols-2 gap-1">
            <Button
              variant="outlined"
              className={clsx({ hidden: !isMobileOnly })}>
              <div className="flex flex-col items-center gap-2 p-4">
                <PhotoIcon />
                Tirar foto
              </div>
            </Button>
            <Button variant="outlined">
              <div className="flex flex-col items-center gap-2 p-4">
                <AttachIcon />
                Enviar imagem
              </div>
            </Button>
          </div>
        </section>
        <Button variant="contained" disabled={sending || success} type="submit">
          Enviar
        </Button>
        {sending && <p className="text-gray-600">Enviando ...</p>}
        {success && (
          <p className="text-green-700">Obrigado! Recebemos sua mensagem!</p>
        )}
        {postError && (
          <p className="text-red-600">Ops! Erro ao enviar o formulário!</p>
        )}

        <address>
          Av. Getúlio Vargas , 887, sala 1101 - Funcionários
          <br />
          Belo Horizonte – MG
        </address>
      </form>
      <img src={side} alt="" className="w-1/3 hidden sm:block" />
    </section>
  );
};
