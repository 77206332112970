import img from './partners.jpg';

export const Partners = () => (
  <section className="container mx-auto py-20">
    <h2 className="text-xl text-gray-600 text-center">
      CLIENTES E PARCEIROS ENERO ENERGIA
    </h2>
    <figure className="mx-auto sm:w-2/3 mt-5 px-3">
      <img src={img} alt="Parceiros" />
    </figure>
  </section>
);
