import starImg from '../../svg/enero-star.svg';

export const History = () => (
  <section className="relative flex items-center flex-col md:flex-row py-10">
    <img src={starImg} alt="" className="w-1/5 md:w-2/3" />
    <div className="p-4 z-10 md:w-2/3">
      <h1 className="text-6xl">NOSSA HISTÓRIA</h1>
      <p className="text-xl mt-2">
        Criada a partir do desejo de impactar positivamente o território em que
        ocupa por meio da geração de energia limpa, a Enero Energia, que integra
        o grupo RO2 Engenharia e Construção, chega ao mercado mineiro com o
        propósito de eliminar burocracias e entregar resultados concretos.
        Oferecendo soluções personalizadas por meio de projetos eficientes, a
        Enero traz em seu DNA a inovação e a confiabilidade conquistadas pela
        atuação de mais de uma década da RO2 no segmento de edifícios
        residenciais e comerciais de alto padrão.
      </p>
    </div>
  </section>
);
