import img1 from './01-investimento.png';
import img2 from './02-fidelidade.png';
import img3 from './03-renovavel.png';
import img4 from './04-impacto.png';
import img5 from './05-instalacoes.png';
import img6 from './06-barata.png';

const Item: React.FC<{ img: string }> = ({ img, children }) => (
  <article className="flex flex-col justify-center animate-fade-in-up">
    <img src={img} alt="" className="" />
    <p className="text-center uppercase text-lg">{children}</p>
  </article>
);

export const BeClient = () => {
  return (
    <section className="bg-darkBg text-white py-20 px-5">
      <main className="container mx-auto">
        <h2 className="text-4xl bg-darkBg">VANTAGENS DE SER CLIENTE ENERO</h2>
        <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-6 gap-4">
          <Item img={img1}>Sem investimento, risco ou burocracia</Item>
          <Item img={img2}>NÃO HÁ PERÍODO DE FIDELIDADE</Item>
          <Item img={img3}>FONTE RENOVÁVEL DE ENERGIA</Item>
          <Item img={img4}>BAIXO IMPACTO AMBIENTAL</Item>
          <Item img={img5}>NÃO É NECESSÁRIO FAZER INSTALAÇÕES</Item>
          <Item img={img6}>16% MAIS BARATA</Item>
        </div>
      </main>
    </section>
  );
};
