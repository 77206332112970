import img from './howto.png';

export const HowToHire = () => (
  <section className="flex items-center py-10">
    <figure className="hidden sm:block">
      <img src={img} alt="" />
    </figure>
    <article className="p-6">
      <h1 className="text-4xl sm:text-6xl">COMO CONTRATAR?</h1>
      <h2 className="text-3xl mt-3 text-brand">É simples!</h2>
      <p className="text-xl  mt-3">
        Basta ter fornecimento de energia elétrica pela Cemig e ter CNPJ. Envie
        sua conta atual de energia para fazermos uma simulação.
      </p>
    </article>
  </section>
);
