import React from 'react';
import './App.css';
import { useRouteMatch } from 'react-router-dom';
import { Routes } from './Routes';
import { Menu } from './components/Menu';
import { BeClient } from './components/BeClient';
import { Partners } from './components/Partners';

function App() {
  const isHome = useRouteMatch({ path: '/' })?.isExact;

  return (
    <main className="flex flex-col min-h-screen">
      <Menu transparent={isHome} showLogo={!isHome} />
      <section className="container mx-auto px-3 sm:px-0 py-1 flex-grow items-stretch">
        <Routes />
      </section>
      <BeClient />
      <Partners />
      <footer className="flex-grow-0 container mx-auto py-5 px-3 sm:px-0 bg-whiteAlpha z-50">
        <div className="flex flex-row justify-between text-gray-300">
          <nav>
            <ul>
              <li>
                <a
                  href="https://www.instagram.com/enero.energia"
                  target="_blank"
                  rel="noreferrer">
                  Instagram @enero.energia
                </a>
              </li>
            </ul>
          </nav>
          <div>
            <span>
              © 2021,<i className="fa fa-heart heart"></i> by Enero
            </span>
          </div>
        </div>
      </footer>
    </main>
  );
}

export default App;
